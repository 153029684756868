import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import SignInForm from "./auth/SignInForm.js";
import SignUpForm from "./auth/SignUpForm.js";
import VideosList from "./videos/VideosList/VideosList.js";
import VideosWatch from "./videos/VideosWatch/VideosWatch.js";
import "./App.scss";

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/auth/signin" component={SignInForm} />
            <Route path="/auth/signup" component={SignUpForm} />
            <Route exact path="/:slug" component={VideosWatch} />
            <Route path="/" component={VideosList} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    ownedVideos: state.ownedVideos
  };
};

export default connect(mapStateToProps)(App);
