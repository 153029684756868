const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_VIDEOS_LIST": {
      return {
        ...state,
        ownedVideos: action.payload.ownedVideos
      };
    }
    case "UPDATE_VIDEO": {
      const ownedVideos = state.ownedVideos.map(video => {
        return video.id === action.payload.id
          ? { ...video, ...action.payload }
          : video;
      });
      return {
        ...state,
        ownedVideos: ownedVideos
      };
    }
    case "DELETE_VIDEO": {
      const ownedVideos = state.ownedVideos.filter(
        video => video.id !== action.payload.id
      );
      return {
        ...state,
        ownedVideos: ownedVideos
      };
    }
    default:
      return state;
  }
};

export default reducer;
