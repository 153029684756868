import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import "./VideosUpload.scss";

class VideosUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoFiles: [],
      isDropZoneActive: true,
    };
    this.fileInput = React.createRef();
    this.video = React.createRef();
    this.canvas = React.createRef();
  }

  getBase64Thumbnail() {
    const width = 640;
    const height = 360;
    this.canvas.current.width = width;
    this.canvas.current.height = height;
    this.canvas.current
      .getContext("2d")
      .drawImage(this.video.current, 0, 0, width, height);
    return this.canvas.current.toDataURL();
  }

  upload() {
    const ONE_GIGABYTE = 1024 ** 3;
    const thumbnail = this.getBase64Thumbnail();
    const videoFile = this.state.videoFiles[0];

    if (videoFile.size > ONE_GIGABYTE) {
      const newVideoObject = {
        status: "error",
        errorMessage: "Maximum upload file size: 1GB",
        title: videoFile.name,
        id: videoFile.name,
      };
      this.props.updateVideosList({
        ownedVideos: [newVideoObject].concat(this.props.ownedVideos),
      });
    } else {
      const initialVideoData = {
        title: videoFile.name,
        thumbnail: thumbnail,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_ROOT_URL}videos/`,
          initialVideoData,
          this.props.axiosConfig
        )
        .then((response) => {
          const newOwnedVideo = response.data.object;
          const s3FileKey = response.data.fields.key;
          this.props.updateVideosList({
            ownedVideos: [newOwnedVideo].concat(this.props.ownedVideos),
          });
          let uploadProgress = 0;
          const axiosConfig = {
            onUploadProgress: (progressEvent) => {
              uploadProgress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.props.updateVideo({
                id: newOwnedVideo.id,
                status: "uploading",
                uploadProgress: uploadProgress,
              });
            },
          };

          const formData = new FormData();
          Object.keys(response.data.fields).forEach((key) =>
            formData.append(key, response.data.fields[key])
          );
          formData.append("file", videoFile, s3FileKey);

          axios
            .post(response.data.url, formData, axiosConfig)
            .then((response) => {
              return axios.post(
                `${process.env.REACT_APP_API_ROOT_URL}videos/${newOwnedVideo.slug}/upload_complete/`,
                { status: "ready" },
                this.props.axiosConfig
              );
            })
            .then(() => {
              this.props.updateVideo({
                id: newOwnedVideo.id,
                status: "ready",
              });
            });
        });
    }

    this.setState({ videoFiles: this.state.videoFiles.slice(1) });
  }

  videoUploadHandler(e) {
    const newVideoFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      newVideoFiles.push(e.target.files[i]);
    }
    this.setState({ videoFiles: this.state.videoFiles.concat(newVideoFiles) });
    e.target.value = null;
  }

  render() {
    const videoFile = this.state.videoFiles[0];

    const thumbnailGenerator = videoFile ? (
      <div className="thumbnail-generator">
        <video
          ref={this.video}
          type="video/mp4"
          src={URL.createObjectURL(videoFile)}
          controls
          muted
          preload="auto"
          onLoadedData={() => this.upload()}
        />
        <canvas ref={this.canvas} />
      </div>
    ) : null;

    return (
      <div className="VideoUpload">
        <div className="hidden">
          <input
            ref={this.fileInput}
            type="file"
            accept="video/mp4"
            multiple
            onChange={(e) => this.videoUploadHandler(e)}
          />
          {thumbnailGenerator}
        </div>
        <button className="btn" onClick={() => this.fileInput.current.click()}>
          Upload video
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    axiosConfig: state.axiosConfig,
    ownedVideos: state.ownedVideos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVideosList: (payload) =>
      dispatch({
        type: "UPDATE_VIDEOS_LIST",
        payload: payload,
      }),
    updateVideo: (payload) =>
      dispatch({
        type: "UPDATE_VIDEO",
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideosUpload);
