import { createStore } from "redux";
import reducer from "./reducer";

const axiosConfig = {
  headers: {
    Authorization: localStorage.getItem("authToken")
  }
};

const preloadedState = {
  isAuthenticated: localStorage.getItem("authToken") ? true : false,
  axiosConfig: localStorage.getItem("authToken") ? axiosConfig : {},
  ownedVideos: null
};

const store = createStore(reducer, preloadedState);

export default store;
