import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../common/Loader.js";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

import thumbnailPlaceholder from "../../../assets/images/thumbnailPlaceholder.png";
import "./VideosListCard.scss";

function VideosListCard(props) {
  let titleInput = React.createRef();

  const deleteVideoHandler = () => {
    axios.delete(
      `${process.env.REACT_APP_API_ROOT_URL}videos/${props.video.slug}/`,
      props.axiosConfig
    );
    props.deleteVideo({ id: props.video.id });
  };

  const updateTitleHandler = (event) => {
    axios.patch(
      `${process.env.REACT_APP_API_ROOT_URL}videos/${props.video.slug}/`,
      { title: event.target.value },
      props.axiosConfig
    );
  };

  const titleKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      titleInput.current.blur();
    }
  };

  const isCreatedMoreThanOneMinuteAgo = () => {
    const now = new Date();
    const createdDate = Date.parse(props.video.created);
    const secondsSinceCreated = (now - createdDate) / 1000;
    return secondsSinceCreated > 60;
  };

  const fullURL = `${window.location.href}${props.video.slug}`;

  let progress = null;
  let imageSrc =
    props.video.hls_status === "ready"
      ? props.video.poster_url
      : props.video.thumbnail;

  if (props.video.status === "uploading") {
    imageSrc = thumbnailPlaceholder;
    progress = (
      <div className="progress">
        <Progress type="circle" percent={props.video.uploadProgress} />
      </div>
    );
  } else if (props.video.status === "waiting") {
    imageSrc = thumbnailPlaceholder;
    progress = (
      <div className="progress">
        {isCreatedMoreThanOneMinuteAgo() ? <h3>Error</h3> : <Loader />}
      </div>
    );
  }

  const videoCard =
    props.video.status === "error" ? (
      <div className="card">
        <div className="card-image">
          <img src={thumbnailPlaceholder} alt={props.video.title} />
          <div className="progress">
            <h3>Error</h3>
            <p>{props.video.errorMessage}</p>
          </div>
        </div>
        <div className="card-info">
          <input type="text" value={props.video.title} readOnly />
        </div>
        <div className="card-meta">
          <p />
          <button className="btn danger" onClick={() => deleteVideoHandler()}>
            Delete
          </button>
        </div>
      </div>
    ) : (
      <div className="card">
        <div className="card-image">
          <Link to={props.video.slug}>
            <img src={imageSrc} alt={props.video.title} />
            {progress}
          </Link>
        </div>
        <div className="card-info">
          <input
            type="text"
            value={props.video.title}
            ref={titleInput}
            onKeyPress={(e) => titleKeyPressHandler(e)}
            onBlur={(e) => updateTitleHandler(e)}
            onChange={(e) =>
              props.updateVideo({
                id: props.video.id,
                title: e.target.value,
              })
            }
          />
          <p>
            <Link to={props.video.slug}>{fullURL}</Link>
          </p>
          <p className="hls-status-text">
            {props.video.hls_status === "ready" ? "HLS" : ""}
          </p>
        </div>
        <div className="card-meta">
          <p>{props.video.views_count} views</p>
          <button className="btn danger" onClick={() => deleteVideoHandler()}>
            Delete
          </button>
        </div>
      </div>
    );

  return <div className="VideosListCard">{videoCard}</div>;
}

const mapStateToProps = (state) => {
  return {
    axiosConfig: state.axiosConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteVideo: (payload) =>
      dispatch({
        type: "DELETE_VIDEO",
        payload: payload,
      }),
    updateVideo: (payload) =>
      dispatch({
        type: "UPDATE_VIDEO",
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideosListCard);
