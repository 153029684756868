import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import videojs from "video.js";

import NavBar from "../../common/NavBar.js";
import NotFound from "../../common/NotFound.js";
import Loader from "../../common/Loader.js";
import downloadIcon from "../../../assets/images/downloadIcon.png";

import "./VideosWatch.scss";
import "video.js/dist/video-js.css";

import qualityLevels from "videojs-contrib-quality-levels";
import qualitySelector from "videojs-hls-quality-selector";

class VideosWatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      lastViewsCountUpdate: new Date(),
      videoDurationSeconds: 30,
    };
  }

  componentDidMount() {
    if (!this.state.video) {
      axios
        .get(
          `${process.env.REACT_APP_API_ROOT_URL}videos/${this.props.match.params.slug}/`
        )
        .then((response) => {
          if (response.data.status === "ready") {
            this.setState(
              {
                video: response.data,
              },
              () => {
                this.createVideoPlayer();
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            video: { status: "error" },
          });
        });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  updateViewsCount() {
    this.setState({
      lastViewsCountUpdate: new Date(),
    });
    axios.post(
      `${process.env.REACT_APP_API_ROOT_URL}videos/${this.props.match.params.slug}/update_views_count/`
    );
  }

  createVideoPlayer() {
    const poster =
      this.state.video.hls_status === "ready"
        ? this.state.video.poster_url
        : this.state.video.thumbnail;

    const sources = this.getVideoSources();

    const playerOptions = {
      autoplay: false,
      controls: true,
      loop: true,
      poster: poster,
      preload: "auto",
      sources: sources,
      html5: {
        hls: {
          overrideNative: true,
        },
      },
    };

    videojs.registerPlugin("qualityLevels", qualityLevels);
    videojs.registerPlugin("hlsQualitySelector", qualitySelector);
    videojs.options.autoSetup = false;

    this.player = videojs(
      this.videoNode,
      playerOptions,
      function onPlayerReady() {}
    );

    this.player.hlsQualitySelector({ displayCurrentQuality: true });
  }

  getVideoSources() {
    const sources = [];

    if (
      this.state.video.hls_status === "ready" &&
      this.props.location.hash === "#mp4"
    ) {
      sources.push({
        src: this.state.video.progressive_mp4_url,
        type: "video/mp4",
      });
    }

    if (this.state.video.hls_status === "ready") {
      sources.push({
        src: this.state.video.hls_url,
        type: "application/vnd.apple.mpegurl",
      });
    }

    sources.push({
      src: this.state.video.url,
      type: "video/mp4",
    });

    return sources;
  }

  videoLoopHandler(event) {
    const secondsSinceLastViewsCountUpdate =
      (new Date() - this.state.lastViewsCountUpdate) / 1000;
    const isAllowedUpdateViewsCount =
      secondsSinceLastViewsCountUpdate > this.state.videoDurationSeconds;
    if (isAllowedUpdateViewsCount && event.target.currentTime === 0) {
      this.updateViewsCount();
    }
  }

  updateVideoDuration(event) {
    this.setState({
      videoDurationSeconds: event.target.duration,
    });
  }

  render() {
    let videoCard = <Loader />;
    if (this.state.video) {
      videoCard =
        this.state.video.status === "ready" ? (
          <div className="video-card">
            <Helmet>
              <title>{this.state.video.title}</title>
              <meta property="og:title" content={this.state.video.title} />
              <meta property="og:type" content="video.other" />
              <meta
                property="og:url"
                content={`${window.location.href}${this.state.video.slug}`}
              />
              <meta property="og:image" content={this.state.video.thumbnail} />
              <meta property="og:video" content={this.state.video.url} />
            </Helmet>
            <div className="video-title">
              <p>{this.state.video.title}</p>
            </div>
            <div data-vjs-player>
              <video
                ref={(node) => (this.videoNode = node)}
                className="video-js vjs-big-play-centered"
                onLoadedData={() => this.updateViewsCount()}
                onLoadedMetadata={(e) => this.updateVideoDuration(e)}
                onSeeked={(e) => this.videoLoopHandler(e)}
              />
            </div>
            <div className="video-details">
              <div className="video-meta">
                <p>{this.state.video.views_count} views</p>
                <a
                  href={
                    this.state.video.progressive_mp4_url || this.state.video.url
                  }
                  download={this.state.video.title}
                  className="download"
                >
                  <img src={downloadIcon} alt="Download" />
                </a>
                <p>{this.state.video.uploader_username}</p>
              </div>
            </div>
          </div>
        ) : (
          <NotFound />
        );
    }

    const toggleHLSLink =
      this.state.video &&
      this.state.video.hls_status === "ready" &&
      this.props.location.hash !== "#mp4" ? (
        <p className="hls-toggle-link">
          <a
            href={this.props.location.pathname + "#mp4"}
            onClick={(e) => {
              e.preventDefault();
              window.location.hash = "#mp4";
              window.location.reload();
            }}
          >
            Switch to MP4
          </a>
        </p>
      ) : null;

    return (
      <React.Fragment>
        <NavBar />
        <div className="VideosWatch">{videoCard}</div>
        {toggleHLSLink}
      </React.Fragment>
    );
  }
}

export default VideosWatch;
