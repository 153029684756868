import React from "react";

import "./NotFound.scss";

function NotFound() {
  return (
    <React.Fragment>
      <h2 className="NotFound">Video not found</h2>
    </React.Fragment>
  );
}

export default NotFound;
