import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";

import Loader from "../common/Loader.js";
import "./AuthForm.scss";

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWaitingForResponse: false,
      errorMessage: {},
      username: "",
      password: ""
    };
  }

  changeWaitingStatus(value) {
    this.setState(state => ({
      ...state,
      isWaitingForResponse: value
    }));
  }

  setErrorMessage(value) {
    this.setState(state => ({
      ...state,
      errorMessage: value
    }));
  }

  signInHandler(e) {
    e.preventDefault();
    this.changeWaitingStatus(true);
    const loginData = {
      username: this.state.username,
      password: this.state.password
    };
    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}auth/get-token/`, loginData)
      .then(response => {
        localStorage.authToken = `Token ${response.data.token}`;
        this.changeWaitingStatus(false);
        window.location.reload();
      })
      .catch(error => {
        this.setErrorMessage(error.response.data);
        this.changeWaitingStatus(false);
      });
  }

  inputChangeHandler(e, payload) {
    this.setState(state => ({
      ...state,
      ...payload
    }));
  }

  render() {
    return this.props.isAuthenticated ? (
      <Redirect to="/" />
    ) : (
      <div className="SignInForm">
        <form onSubmit={e => this.signInHandler(e)}>
          <h2>Sign In</h2>
          <div className="error">
            <p className="errorMessage">
              {this.state.errorMessage.non_field_errors}
            </p>
          </div>
          <div
            className={`form-group ${
              this.state.errorMessage.username ? "error" : ""
            }`}
          >
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              value={this.state.username}
              onChange={e =>
                this.inputChangeHandler(e, { username: e.target.value })
              }
            />
            <p className="errorMessage">{this.state.errorMessage.username}</p>
          </div>
          <div
            className={`form-group ${
              this.state.errorMessage.password ? "error" : ""
            }`}
          >
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={this.state.password}
              onChange={e =>
                this.inputChangeHandler(e, { password: e.target.value })
              }
            />
            <p className="errorMessage">{this.state.errorMessage.password}</p>
          </div>
          {this.state.isWaitingForResponse ? (
            <Loader size="small" />
          ) : (
            <button className="btn btn-lg">Sign In</button>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    ownedVideos: state.ownedVideos
  };
};

export default connect(mapStateToProps)(SignInForm);
