import React from "react";

import "./Loader.scss";

function Loader(props) {
  const classes = `Loader ${props.size}`;
  return (
    <div className={classes}>
      <div className="outer" />
      <div className="middle" />
      <div className="inner" />
    </div>
  );
}

export default Loader;
