import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";

import NavBar from "../../common/NavBar.js";
import VideosUpload from "./VideosUpload.js";
import VideosListCard from "./VideosListCard.js";
import Loader from "../../common/Loader.js";

import "./VideosList.scss";

class VideosList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      numberOfDisplayedVideos: 12
    };
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    if (this.props.ownedVideos === null) {
      this.setState({ isLoading: true });
    }
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT_URL}videos/`,
        this.props.axiosConfig
      )
      .then(response => {
        this.props.updateVideosList({ ownedVideos: response.data });
        this.setState({ isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  }

  loadMoreHandler(e) {
    e.preventDefault();
    this.setState(state => ({
      ...state,
      numberOfDisplayedVideos: state.numberOfDisplayedVideos + 12
    }));
  }

  render() {
    let videos = null;

    if (this.props.ownedVideos) {
      videos = this.props.ownedVideos
        .slice(0, this.state.numberOfDisplayedVideos)
        .map(video => (
          <VideosListCard
            video={video}
            key={video.id}
            axiosConfig={this.props.axiosConfig}
          />
        ));
    }

    const loadMoreButton =
      this.props.ownedVideos &&
      this.props.ownedVideos.length > this.state.numberOfDisplayedVideos ? (
        <button
          className="btn load-more-btn"
          onClick={e => this.loadMoreHandler(e)}
        >
          More Videos
        </button>
      ) : null;

    return !this.props.isAuthenticated ? (
      <Redirect to="/auth/signin" />
    ) : (
      <React.Fragment>
        <NavBar />
        <div className="VideosList">
          <VideosUpload />
          <div className="videos">
            {this.state.isLoading ? <Loader /> : videos}
          </div>
          {loadMoreButton}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated,
    axiosConfig: state.axiosConfig,
    ownedVideos: state.ownedVideos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateVideosList: payload =>
      dispatch({
        type: "UPDATE_VIDEOS_LIST",
        payload: payload
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideosList);
