import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./NavBar.scss";

function NavBar(props) {
  const signOutHandler = () => {
    localStorage.removeItem("authToken");
    window.location.reload();
  };

  const homeLink = props.isAuthenticated ? (
    <Link to="/">My Videos</Link>
  ) : (
    <div />
  );

  const authLink = props.isAuthenticated ? (
    <button onClick={signOutHandler}>Sign Out</button>
  ) : (
    <Link to="/auth/signin">Sign In</Link>
  );

  return (
    <div className="NavBar">
      {homeLink}
      {authLink}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated
  };
};

export default connect(mapStateToProps)(NavBar);
